import { Checkbox, CircularProgress, useMediaQuery } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { navigate, Link } from "gatsby"
import { Button } from "../ui/Button"
import { addToCart } from "../../checkout/events"
import { useCheckoutDispatch, useCheckoutState } from "../../checkout/state"
import { getCartProduct, getStripeId } from "../../utils/stripejs"
import theme from "../../material-ui/theme"
import { useLazyApi } from "../../hooks/useLazyApi"
import { useAuthState } from "../../contexts/authContext"
import { Headset } from "../user/Dashboard"
import { getLs } from "../../utils/localStorage"
import { formatCurrencyString, formatDate } from "../../utils/format"
import { isSubscriptionInCart } from "../../checkout/shared"
import LoadingSpinner from "../ui/LoadingSpinner"
import { Currency } from "../../checkout/types/checkoutTypes"
import { CartProduct } from "../../ts/interfaces"

type PageState =
  | "NOT_LOGGED_IN"
  | "NO_TRIAL"
  | "ACTIVE_MEMBER"
  | "LOADING"
  | "MEMBERSHIP_IN_CART"

const Container = styled.div<{ isMobile: boolean; isPromo: boolean }>`
  display: grid;
  grid-template-columns: ${props =>
    props.isMobile || props.isPromo
      ? "minmax(350px, 350px)"
      : "minmax(375px, 375px) minmax(375px, 375px)"};
  gap: 27px;
  width: 60%;
  margin: 0 auto;
  align-items: stretch;
  justify-content: center;
`

const Box = styled.div<{
  checked: boolean
  isMobile: boolean
  hasBullets: boolean
  isPromo: boolean
}>`
  border-radius: 20px;
  border: ${props =>
    props.checked ? "4px solid #1d99b8" : "2px solid #A7A9AC"};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: ${props =>
    props.isMobile || !props.hasBullets ? "15px 20px" : "15px 30px 50px 30px"};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${props => (props.isMobile && !props.isPromo ? "300px" : "auto")};
  cursor: pointer;
`

const TitleContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`

const Title = styled.h2<{ isMobile: boolean }>`
  text-align: center;
  font-family: Rubik;
  font-size: ${isMobile => (isMobile ? "24px" : "26px")};
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 107.692% */
  letter-spacing: 0.26px;

  background: linear-gradient(90deg, #1d99b8 0%, #5c38bd 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const PeriodPrice = styled.div`
  color: #454545;
  text-align: center;
  font-family: Rubik;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 107.692% */
  letter-spacing: 0.26px;
  margin-bottom: 8px;
`

const Dollar = styled.span<{ isMobile: boolean }>`
  color: #454545;
  font-family: Rubik;
  font-size: ${props => (props.isMobile ? "30px" : "32px")};
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.32px;
`

const Amount = styled.span<{ isMobile: boolean }>`
  color: #454545;
  font-family: Rubik;
  font-size: ${props => (props.isMobile ? "56px" : "60px")};
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.64px;
`

const AmountTotal = styled.div<{ isMobile: boolean }>`
  color: #1d99b8;
  font-family: Rubik;
  font-size: ${props => (props.isMobile ? "18px" : "20px")};
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.24px;
  margin-bottom: 14px;
  min-height: ${props => (props.isMobile ? "0" : "28px")};
`

const Bullets = styled.div`
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 14px;
`

const Bullet = styled.div`
  padding: 0 19px;
`

const Checkmark = styled.div<{ isMobile: boolean }>`
  display: inline-block;
  transform: rotate(45deg);
  height: 12px;
  width: 6px;
  border-bottom: 4px solid lightgray;
  border-right: 4px solid lightgray;
  margin-right: 14px;
`

const BulletText = styled.span<{ isMobile: boolean }>`
  color: #454545;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
`

const Emphasized = styled.div<{ isMobile: boolean }>`
  color: #b632b0;
  text-align: center;
  font-family: Rubik;
  font-size: ${props => (props.isMobile ? "20px" : "22px")};
  font-style: normal;
  font-weight: 500;
  line-height: 39px; /* 177.273% */
  letter-spacing: 0.22px;
`

const StylecdCheckbox = styled(Checkbox)`
  margin-right: -12px;
  & .MuiSvgIcon-root {
    font-size: 40px;
  }
`

const ButtonContainer = styled.div<{ isPromo: boolean }>`
  display: flex;
  justify-content: center;
  margin-top: ${props => (props.isPromo ? "32px" : "64px")};
`

const MembershipInfo = styled.div`
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-top: 1rem;
  color: var(--color-purple);
`

const TextContainer = styled.div<{ isMobile: boolean }>`
  margin: 0 auto;
  width: ${props => (props.isMobile ? "100%" : "50%")};
  padding-bottom: 2rem;
  text-align: center;
`

const MembershipSection = (section: any) => {
  const checkoutState = useCheckoutState()
  const { cartItems, currency } = checkoutState
  const checkoutDispatch = useCheckoutDispatch()
  const authState = useAuthState()

  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const [pageStage, setPageState] = useState<PageState>("LOADING")
  const [selectedProductIds, setSelectedProductIds] = useState({
    id: "",
    testId: "",
  })
  const [checkedBoxIdx, setCheckedBoxIdx] = useState(0)

  const confCode = getLs("lastConfirmationCode")
  const params = confCode ? { confCode: confCode.code } : {}

  // TODO: There's an issue with the useLazyApi loading state - loadingAuth is overriding queryResult.loading
  const [fetchOrders, { loading, error, data, errMsg }] = useLazyApi(
    "billing",
    "GetUserOrders"
  )

  const { isPromo } = section

  useEffect(() => {
    if (section.membership) {
      const annual = section.membership.find(
        m => m.product.subscriptionPeriod === "yearly"
      )
      if (annual) {
        setSelectedProductIds({
          id: annual.product.stripeId,
          testId: annual.product.stripeTestId,
        })
      }
    }
  }, [section.membership])

  const handleBuySubscription = () => {
    addToCart(
      checkoutState,
      checkoutDispatch,
      1,
      selectedProductIds.id,
      selectedProductIds.testId
    )
    if (section.isModalOpen) {
      section.setIsModalOpen(false)
    } else {
      navigate("/store/checkout")
    }
  }
  const getAmmount = (currency: Currency, product?: CartProduct | null) => {
    const ammount =
      (currency === "cad"
        ? product?.prices?.cad?.unit_amount
        : product?.prices?.usd?.unit_amount) || 0
    return ammount
  }

  const getMonthlyPricePerMonth = (
    id: string,
    testId: string,
    currency: Currency
  ) => {
    const monthlyStripeId = getStripeId(id, testId)
    const monthlyProduct = getCartProduct(monthlyStripeId)
    const amountPerMonth = getAmmount(currency, monthlyProduct)

    return formatCurrencyString(
      { value: amountPerMonth, currency: currency.toUpperCase() },
      false
    )
  }

  const getAnnualPricePerMonth = (
    id: string,
    testId: string,
    currency: Currency
  ) => {
    const annualStripeId = getStripeId(id, testId)
    const annualProduct = getCartProduct(annualStripeId)
    const amountPerYear = getAmmount(currency, annualProduct)

    return formatCurrencyString(
      { value: amountPerYear / 12, currency: currency.toUpperCase() },
      false
    )
  }

  const getAnnualPricePerYear = (
    id: string,
    testId: string,
    currency: Currency
  ) => {
    const annualStripeId = getStripeId(id, testId)
    const annualProduct = getCartProduct(annualStripeId)
    const amountPerYear = getAmmount(currency, annualProduct)
    return formatCurrencyString(
      { value: amountPerYear, currency: currency.toUpperCase() },
      false
    )
  }

  useEffect(() => {
    if (authState.isLoggedIn() && !data) {
      setPageState("LOADING")
      const fetchAsync = async () => {
        fetchOrders({
          variables: {
            ...params,
            email: authState.email,
          },
        })
      }
      fetchAsync()
      return
    } else if (isSubscriptionInCart(cartItems)) {
      setPageState("MEMBERSHIP_IN_CART")
      return
    } else {
      if (!data) {
        setPageState("NO_TRIAL")
        return
      }

      const activeMembership = data.User.membership

      if (activeMembership && activeMembership.status === "active") {
        //they are an active member with a card on file...
        setPageState("ACTIVE_MEMBER")
      } else {
        setPageState("NO_TRIAL")
      }
    }
  }, [authState, data])

  const trialDaysLeftText = () => {
    if (!data?.User) {
      return
    }

    const headsets: Headset[] = data.User.headset
    const activeMembership = data.User.membership

    if (
      headsets &&
      headsets.length > 0 &&
      headsets.some(hs => hs.trialActivated === false)
    ) {
      const headset = headsets.find(hs => hs.trialActivated === false)
      const endDate = new Date()
      endDate.setDate(endDate.getDate() + headset!.trialNumberDays)
      const endDateFormatted = formatDate({ date: endDate, fullMonth: true })

      return (
        <p>
          `You have a free membership available. Please provide a credit card in
          order to activate your membership. You will not be charged until $
          {endDateFormatted}.`
        </p>
      )
    } else if (
      activeMembership &&
      activeMembership.subscription?.cancelAtPeriodEnd
    ) {
      const dateStr = new Date(
        activeMembership.subscription.currentPeriodEnd.formatted
      ).toLocaleDateString()
      return (
        <p>
          `Your membership will remain active until {dateStr}. If you would like
          to reactivate, you can start a new membership after {dateStr}.`
        </p>
      )
    }
  }

  const renderSelectMembership = (disablePurchase = false) => (
    <>
      <Container isMobile={isMobile} isPromo={isPromo}>
        {section &&
          section.membership &&
          section.membership
            .filter(m => !isPromo || m.product.subscriptionPeriod === "yearly")
            .map((membership, i) => {
              const { stripeId, stripeTestId } = membership.product
              const isAnnual =
                membership.product.subscriptionPeriod === "yearly"
              const hasBullets = !!membership.bulletPoints

              return (
                <Box
                  key={i}
                  checked={checkedBoxIdx === i}
                  isMobile={isMobile}
                  onClick={() => {
                    setSelectedProductIds({
                      id: stripeId,
                      testId: stripeTestId,
                    })
                    setCheckedBoxIdx(i)
                  }}
                  hasBullets={hasBullets}
                  isPromo={isPromo}
                >
                  <TitleContainer isMobile={isMobile}>
                    <Title isMobile={isMobile}>
                      {membership.product.name || membership.title}
                    </Title>
                    <StylecdCheckbox checked={checkedBoxIdx === i} />
                  </TitleContainer>
                  <PeriodPrice>
                    <Dollar isMobile>$</Dollar>
                    {/* TODO: Get this from the Strapi collection */}
                    <Amount isMobile={isMobile}>
                      {isAnnual
                        ? getAnnualPricePerMonth(
                            stripeId,
                            stripeTestId,
                            currency
                          )
                        : getMonthlyPricePerMonth(
                            stripeId,
                            stripeTestId,
                            currency
                          )}
                    </Amount>
                    /month
                  </PeriodPrice>
                  <AmountTotal isMobile={isMobile}>
                    {" "}
                    {isAnnual
                      ? `($${getAnnualPricePerYear(
                          stripeId,
                          stripeTestId,
                          currency
                        )} total, billed yearly)`
                      : ""}
                  </AmountTotal>
                  {hasBullets && (
                    <Bullets>
                      {membership.bulletPoints.map((bullet, i) => (
                        <Bullet key={i}>
                          <Checkmark isMobile={isMobile} />
                          <BulletText isMobile={isMobile}>
                            {bullet.text}
                          </BulletText>
                        </Bullet>
                      ))}
                    </Bullets>
                  )}
                  {isAnnual && membership.featuredText && (
                    <Emphasized isMobile={isMobile}>
                      {membership.featuredText}
                    </Emphasized>
                  )}
                </Box>
              )
            })}
      </Container>
      <ButtonContainer isPromo={isPromo}>
        <Button
          type="primary"
          title={section.buttonText}
          sectionId="membership" // TODO: confirm
          // id={
          //   priceObj.isBilledMonthly
          //     ? "continue_monthly"
          //     : "continue_yearly"
          // }
          onClick={handleBuySubscription}
          disabled={
            disablePurchase ||
            isSubscriptionInCart(cartItems) ||
            pageStage === "LOADING"
          }
        />
      </ButtonContainer>
    </>
  )

  const renderTrialAvailable = () => {
    return (
      <>
        <TextContainer isMobile={isMobile}>
          <MembershipInfo>{trialDaysLeftText()}</MembershipInfo>
        </TextContainer>
      </>
    )
  }

  const renderActiveMember = () => (
    <TextContainer isMobile={isMobile}>
      <div style={{ textAlign: "center" }}>
        {trialDaysLeftText()}
        <p>
          You have an active membership, please{" "}
          <Link to="/user">see your account</Link> for more details.
        </p>
      </div>
    </TextContainer>
  )
  const renderMembershipInCart = () => (
    <TextContainer isMobile={isMobile}>
      <div style={{ textAlign: "center" }}>
        {trialDaysLeftText()}
        <p>
          You already have a membership in your cart. {` `}
          <Link to="/store/checkout">View your cart.</Link>
        </p>
      </div>
    </TextContainer>
  )

  switch (pageStage) {
    case "LOADING":
      return <LoadingSpinner />
    case "ACTIVE_MEMBER":
      return (
        <>
          {renderSelectMembership(true)}
          {renderActiveMember()}
        </>
      )
    case "MEMBERSHIP_IN_CART":
      return (
        <>
          {renderSelectMembership(true)}
          {renderMembershipInCart()}
        </>
      )
    default:
      return renderSelectMembership()
  }
}

export default MembershipSection
